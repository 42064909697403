module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-155272584-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[],"showInProduction":false,"axeOptions":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://raindrop.com","noTrailingSlash":true,"noQueryString":true,"noHash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Raindrop","short_name":"Raindrop","description":"Complete Enterprise Spend Management Solution","start_url":"/","background_color":"#ffffff","theme_color":"#34a1d5","display":"minimal-ui","icon":"src/assets/images/icon.png","lang":"en","icons":[{"src":"assets/images/icon_512x512.png","sizes":"512x512"},{"src":"assets/images/icon_192x192.png","sizes":"192x192"}],"icon_options":{"purpose":"any maskable"},"localize":[{"start_url":"/","lang":"en","name":"Raindrop","short_name":"Raindrop","description":"Complete Enterprise Spend Management Solution"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b490cf6b0bcf2a80c54e18b649634c63"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
